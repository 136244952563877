import { StoplightDuotoneIcon } from '@expo/styleguide-icons/custom/StoplightDuotoneIcon';

import { ReviewTeamIllustration } from '~/scenes/HomeScene/illustrations/ReviewTeamIllustration';
import Content from '~/ui/patterns/layouts/Content';

import { Timeline } from '../components/Timeline';

export function ReviewSection() {
  return (
    <section>
      <Content>
        <Timeline
          title="Review"
          Icon={StoplightDuotoneIcon}
          theme="orange"
          entries={[
            {
              title: 'Invite your team',
              description:
                'With adjustable roles, your whole team can contribute, test, and view the state of your deployments.',
              content: <ReviewTeamIllustration />,
              className: 'mt-12',
            },
            {
              title: 'Upgrade your PR reviews',
              description:
                'Commit, push to GitHub, and generate a QR code. That’s all that’s needed to let your team review a feature before merging.',
              content: (
                <picture>
                  <img
                    className="asset-sm-shadow hidden w-full max-w-[522px] cursor-default select-none dark:block"
                    src="/static/home/review-github-dark.png"
                    alt="Expo workflow"
                    width={1046}
                    height={689}
                    loading="lazy"
                  />
                  <img
                    className="asset-sm-shadow w-full max-w-[522px] cursor-default select-none dark:hidden"
                    src="/static/home/review-github.png"
                    alt="Expo workflow"
                    width={1046}
                    height={689}
                    loading="lazy"
                  />
                </picture>
              ),
              className: 'mt-44',
            },
            {
              title: 'Run end-to-end tests',
              description: 'Use Detox to run automated end-to-end tests on EAS.',
              content: (
                <img
                  src="/static/home/review-e2e.webp"
                  alt="Fast refresh"
                  width={1029}
                  height={377}
                  className="asset-shadow pointer-events-none select-none"
                  loading="lazy"
                />
              ),
              className: '-mt-48 max-lg-gutters:-mt-32',
            },
          ]}
        />
      </Content>
    </section>
  );
}
