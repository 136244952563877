import { mergeClasses } from '@expo/styleguide';

import { P } from '~/ui/components/text';

type Props = {
  content: string;
};

export function TweetContent({ content }: Props) {
  const mentionRegex = /@[a-z]*/gi;
  const mentions = content.match(mentionRegex) ?? [];
  const parts = content.split(new RegExp(`(${mentions.join('|')})`));

  return (
    <P className="!whitespace-pre-line">
      {parts.map((part, index) => (
        <span className={mergeClasses(part.match(mentionRegex) && 'text-link')} key={part + index}>
          {part}
        </span>
      ))}
    </P>
  );
}
