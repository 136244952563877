import { Button, mergeClasses } from '@expo/styleguide';
import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';
import { motion, Variants } from 'framer-motion';
import type { ReactElement } from 'react';

import { CALLOUT, H4 } from '~/ui/components/text';

type Props = {
  title: string;
  description: string;
  href: string;
  imageUrl: string;
  cta?: ReactElement;
  className?: string;
  overlayClassName?: string;
};

const snackAppVariants: Variants = {
  offscreen: {
    scale: 1,
  },
  onscreen: {
    scale: [1, 1.025, 1],
    transition: {
      type: 'tween',
      duration: 0.75,
    },
  },
};

export function SnackApp({
  title,
  description,
  href,
  imageUrl,
  cta,
  className,
  overlayClassName,
}: Props) {
  return (
    <motion.div variants={snackAppVariants} className="flex flex-col items-center gap-1.5">
      <div
        className={mergeClasses(
          'dark-theme relative min-h-[512px] min-w-[270px] duration-500 hocus:scale-1025',
          className
        )}>
        <div
          className={mergeClasses(
            'absolute bottom-[30px] z-10 mx-[31px] rounded-b-2xl bg-[rgba(32,42,47,0.75)] px-6 py-5 backdrop-blur-sm',
            overlayClassName
          )}>
          <H4 tag="h3">{title}</H4>
          <CALLOUT className="opacity-80">{description}</CALLOUT>
          <Button
            theme="quaternary"
            rightSlot={<ArrowUpRightIcon className="text-palette-gray6" />}
            href={href}
            openInNewTab
            className={mergeClasses(
              'mb-2 mt-4 w-full justify-center rounded-full bg-palette-white text-palette-black',
              'hocus:bg-palette-white hocus:text-palette-black hocus:opacity-80'
            )}>
            Try It
          </Button>
        </div>
        <img
          src={imageUrl}
          className="absolute inset-0"
          alt={title}
          loading="lazy"
          width="100%"
          height="100%"
        />
      </div>
      {cta}
    </motion.div>
  );
}
