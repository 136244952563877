import { Button, mergeClasses } from '@expo/styleguide';
import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';
import { Earth02Icon } from '@expo/styleguide-icons/outline/Earth02Icon';
import { motion } from 'framer-motion';

import { FramerHorizontalScroll } from '~/scenes/HomeScene/components/FramerHorizontalScroll';
import { SnackApp } from '~/scenes/HomeScene/components/SnackApp';
import { Tag } from '~/ui/components/Tag';
import { H2, P } from '~/ui/components/text';
import Content from '~/ui/patterns/layouts/Content';

export function SnacksSection() {
  return (
    <section className="pb-24 pt-32 max-md-gutters:py-16">
      <Content>
        <div
          className={mergeClasses(
            'flex gap-16',
            'max-xl-gutters:flex-col max-xl-gutters:items-center',
            'max-md-gutters:gap-8'
          )}>
          <div className="flex flex-col gap-6">
            <H2 size="xl" className="mt-6 font-bold">
              Try it for yourself
            </H2>
            <P theme="secondary">
              Fast animations. Genuine native components. Edit in your browser and preview on your
              device in seconds.
            </P>
            <div>
              <P theme="secondary" className="mb-3">
                Runs natively on:
              </P>
              <div className="flex gap-2">
                <Tag title="Android" icon="Android" className="select-none" />
                <Tag title="iOS" icon="Apple" className="select-none" />
                <Tag title="Web" customIcon={Earth02Icon} className="select-none" />
              </div>
            </div>
          </div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.66 }}
            transition={{ staggerChildren: 0.15, duration: 0.5 }}>
            <FramerHorizontalScroll>
              <SnackApp
                title="Finance app"
                description="Track expenses. 60 FPS animations."
                href="https://snack.expo.dev/@expo-team-snacks/finance-app"
                imageUrl="/static/home/app-finance.webp"
                className="min-w-[278px]"
                overlayClassName="bottom-[26px] rounded-b-[36px] min-h-[174px]"
              />
              <SnackApp
                title="Image app"
                description="Upload images. Add stickers. Save to device."
                href="https://snack.expo.dev/@expo-team-snacks/image-app"
                imageUrl="/static/home/app-image.webp"
                cta={
                  <Button
                    openInNewTab
                    theme="quaternary"
                    rightSlot={<ArrowUpRightIcon />}
                    href="https://docs.expo.dev/tutorial/create-your-first-app"
                    className="relative -mt-3 text-secondary">
                    Build It Step-by-Step
                  </Button>
                }
              />
              <SnackApp
                title="Social media app"
                description="Native navigation. Fast lists. Ready to scroll."
                imageUrl="/static/home/app-social.webp"
                href="https://snack.expo.dev/@expo-team-snacks/social-app"
                className="min-w-[266px]"
                overlayClassName="mx-6 rounded-b-md min-h-[170px] left-[-1px]"
              />
            </FramerHorizontalScroll>
          </motion.div>
        </div>
      </Content>
    </section>
  );
}
