import { Button } from '@expo/styleguide';
import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';
import { PropsWithChildren } from 'react';

import { CALLOUT, H1, H3, P } from '~/ui/components/text';
import Content from '~/ui/patterns/layouts/Content';

function FeatureToken({ children }: PropsWithChildren) {
  return (
    <span className="transition-colors duration-[250ms] hocus:text-transparent">{children}</span>
  );
}

export function FeaturesSection() {
  return (
    <section className="dark-theme bg-[#1A1D1E] py-24">
      <Content className="!max-w-[600px]">
        <H1 tag="h2" className="text-center">
          And you’ll get so much more…
        </H1>
        <div className="my-14 flex cursor-default flex-col gap-8 bg-statement-gradient bg-clip-text">
          <H3>
            <FeatureToken>Organization accounts.</FeatureToken>{' '}
            <FeatureToken>Production-like development server.</FeatureToken>{' '}
            <FeatureToken>Developer tools built in.</FeatureToken>{' '}
            <FeatureToken>Automatic native code configuration.</FeatureToken>{' '}
            <FeatureToken>Use any native code.</FeatureToken>{' '}
            <FeatureToken>Simulator builds.</FeatureToken>{' '}
            <FeatureToken>File-based routing.</FeatureToken>{' '}
            <FeatureToken>Automatic build submissions.</FeatureToken>{' '}
            <FeatureToken>App store metadata verification.</FeatureToken>{' '}
            <FeatureToken>Usage-based pricing.</FeatureToken>{' '}
            <FeatureToken>Team credential management.</FeatureToken>
          </H3>
          <P weight="medium">
            <FeatureToken>Preview updates with a QR code.</FeatureToken>{' '}
            <FeatureToken>Save app store metadata.</FeatureToken>{' '}
            <FeatureToken>Automatic app store credentials management.</FeatureToken>{' '}
            <FeatureToken>Ad-hoc builds.</FeatureToken>{' '}
            <FeatureToken>End-to-end code signing.</FeatureToken>{' '}
            <FeatureToken>Build logs.</FeatureToken> <FeatureToken>Member roles.</FeatureToken>{' '}
            <FeatureToken>Web dashboards.</FeatureToken>{' '}
            <FeatureToken>Submission logs.</FeatureToken>{' '}
            <FeatureToken>Top-notch universal modules.</FeatureToken>{' '}
            <FeatureToken>Expo Discord community.</FeatureToken>{' '}
            <FeatureToken>End-to-end testing.</FeatureToken>
          </P>
          <CALLOUT theme="secondary" weight="medium">
            <FeatureToken>Regular SDK releases.</FeatureToken>{' '}
            <FeatureToken>Apple silicon builders for iOS.</FeatureToken>{' '}
            <FeatureToken>Automatic version incrementing in CI.</FeatureToken>{' '}
            <FeatureToken>GitHub Actions-ready.</FeatureToken>{' '}
            <FeatureToken>Shareable Snack playgrounds.</FeatureToken>{' '}
            <FeatureToken>VS Code extension.</FeatureToken>{' '}
          </CALLOUT>
        </div>
        <div className="text-center">
          <Button
            className="dark-theme border-palette-white bg-palette-white text-palette-black hocus:bg-palette-gray12"
            size="lg"
            rightSlot={<ArrowUpRightIcon className="text-icon-default" />}
            href="https://docs.expo.dev"
            openInNewTab>
            Get Started
          </Button>
        </div>
      </Content>
    </section>
  );
}
