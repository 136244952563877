import { isMobileBrowser } from '~/common/window';
import { usePageProps } from '~/providers/PagePropsContext';

export function usePlatform() {
  const pageProps = usePageProps();

  function checkMobileBrowser() {
    return isMobileBrowser(pageProps.userAgent);
  }

  return {
    isMobileBrowser: checkMobileBrowser(),
  };
}
