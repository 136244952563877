export function ReviewTeamIllustration() {
  return (
    <svg
      viewBox="0 0 515 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mt-2 inline-block w-full max-w-[522px]">
      <rect y="14" width="515" height="131" rx="12" className="fill-palette-orange3" />
      <image href="/static/home/review-team-avatars.webp" x="164" width="184" height="160" />
    </svg>
  );
}
