import { mergeClasses } from '@expo/styleguide';
import { motion } from 'framer-motion';
import { HTMLAttributes, ReactElement } from 'react';

import { H3, P } from '~/ui/components/text';

type Props = {
  title: string;
  description: string;
  content?: ReactElement;
  indicatorBorder: string;
} & Omit<HTMLAttributes<HTMLDivElement>, 'content'>;

export function TimelineEntry({ title, description, content, indicatorBorder, className }: Props) {
  return (
    <div className={mergeClasses('relative max-lg-gutters:!mt-4', className)}>
      <motion.em
        className={mergeClasses(
          'absolute z-10 mx-1.5 block size-5 rounded-full border-2 bg-default',
          indicatorBorder
        )}
        initial="offscreen"
        whileInView="onscreen"
        transition={{ duration: 1, delay: 0.25 }}
        viewport={{ once: true, amount: 1 }}
        variants={{
          offscreen: {
            scale: 0,
          },
          onscreen: {
            scale: [0, 1.2, 1],
          },
        }}
      />
      <H3 className="mb-1">{title}</H3>
      <P theme="secondary" className="mb-6">
        {description}
      </P>
      <div className="flex justify-center">{content}</div>
    </div>
  );
}
