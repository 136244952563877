import { mergeClasses } from '@expo/styleguide';
import { BuildIcon } from '@expo/styleguide-icons/custom/BuildIcon';
import { EasSubmitIcon } from '@expo/styleguide-icons/custom/EasSubmitIcon';
import { LayersTwo02Icon } from '@expo/styleguide-icons/outline/LayersTwo02Icon';
import { LifeBuoy01Icon } from '@expo/styleguide-icons/outline/LifeBuoy01Icon';
import { LineChartUp02Icon } from '@expo/styleguide-icons/outline/LineChartUp02Icon';
import { PieChart03Icon } from '@expo/styleguide-icons/outline/PieChart03Icon';
import { motion } from 'framer-motion';

import { ServiceTile } from '~/scenes/HomeScene/components/ServiceTile';
import { DotsPattern } from '~/scenes/HomeScene/illustrations/DotsPattern';
import { H2 } from '~/ui/components/text';
import Content from '~/ui/patterns/layouts/Content';

export function ServicesSection() {
  return (
    <motion.section
      className="dark-theme relative bg-[#1A1D1E] py-24"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.45 }}
      transition={{ staggerChildren: 0.05 }}>
      <DotsPattern name="servicesPattern" className="dark-theme fill-icon-quaternary" />
      <Content>
        <H2 className="mb-12 text-center">Services made for professionals</H2>
        <div
          className={mergeClasses(
            'mx-auto grid max-w-[775px] grid-cols-3 gap-6',
            'max-lg-gutters:grid-cols-2',
            'max-sm-gutters:grid-cols-auto-min-1'
          )}>
          <ServiceTile
            Icon={BuildIcon}
            title="Build"
            description="Create builds for your team and for the app stores."
          />
          <ServiceTile
            Icon={EasSubmitIcon}
            title="Submit"
            description="Submit apps to the App Store and Google Play store."
          />
          <ServiceTile
            Icon={LayersTwo02Icon}
            title="Update"
            description="Get the latest changes to your users in minutes, not days."
          />
          <ServiceTile
            Icon={PieChart03Icon}
            title="Insights"
            description="Learn how users use your app as you deploy."
          />
          <ServiceTile
            Icon={LineChartUp02Icon}
            title="On-demand"
            description="Pay for the services you use, as you grow."
          />
          <ServiceTile
            Icon={LifeBuoy01Icon}
            title="Support"
            description="Get unstuck with help from the Expo team."
          />
        </div>
      </Content>
    </motion.section>
  );
}
