import { Button } from '@expo/styleguide';
import { ComponentType, HTMLAttributes } from 'react';

type Props = {
  name: string;
  imageUrl: string;
  href: string;
  Icon: ComponentType<HTMLAttributes<SVGSVGElement>>;
};

export function DevelopAPITile({ href, name, imageUrl, Icon }: Props) {
  return (
    <div className="flex min-w-[250px] flex-col justify-between gap-5">
      <img
        src={imageUrl}
        alt={`${name} API showcase`}
        className="asset-shadow pointer-events-none select-none"
        loading="lazy"
        width="100%"
        height="100%"
      />
      <Button
        openInNewTab
        href={href}
        theme="secondary"
        className="mx-10 mt-3 justify-center border-secondary text-secondary"
        leftSlot={<Icon className="text-icon-tertiary" />}
        skipCapitalization>
        {name}
      </Button>
    </div>
  );
}
