import { mergeClasses } from '@expo/styleguide';
import { TerminalBrowserDuotoneIcon } from '@expo/styleguide-icons/duotone/TerminalBrowserDuotoneIcon';
import { Camera02Icon } from '@expo/styleguide-icons/outline/Camera02Icon';
import { Compass03Icon } from '@expo/styleguide-icons/outline/Compass03Icon';
import { Image01Icon } from '@expo/styleguide-icons/outline/Image01Icon';
import { Map01Icon } from '@expo/styleguide-icons/outline/Map01Icon';

import { DevelopAPITile } from '~/scenes/HomeScene/components/DevelopAPITile';
import { FramerHorizontalScroll } from '~/scenes/HomeScene/components/FramerHorizontalScroll';
import { Timeline } from '~/scenes/HomeScene/components/Timeline';
import Content from '~/ui/patterns/layouts/Content';

export function DevelopSection() {
  return (
    <section className="pt-20">
      <Content>
        <Timeline
          title="Develop"
          Icon={TerminalBrowserDuotoneIcon}
          entries={[
            {
              title: 'Get started',
              description: 'Initialize a new project or bring an existing React Native project.',
              content: (
                <img
                  src="/static/home/cli-terminal.png"
                  alt="Expo CLI"
                  className="asset-shadow pointer-events-none select-none"
                  loading="lazy"
                  width={1029}
                  height={313}
                />
              ),
              className: 'mt-12',
            },
            {
              title: 'File-based routing, TypeScript ready',
              description:
                'Create stack and modal screens with minimal boilerplate using your filesystem. Feel confident with TypeScript.',
              content: (
                <img
                  src="/static/home/editor-router.png"
                  alt="File-based routing"
                  className="asset-shadow pointer-events-none !w-full select-none"
                  loading="lazy"
                  width={1047}
                  height={823}
                />
              ),
              className: 'mt-48',
            },
            {
              title: 'Get going, then keep going with fast refresh',
              description:
                'Run your project on your own device with Expo Go. See your app update in milliseconds as you make changes.',
              content: (
                <picture>
                  <img
                    className="asset-shadow hidden w-full max-w-[522px] cursor-default select-none dark:block"
                    src="/static/home/hello-world-dark.png"
                    alt="Expo workflow"
                    width={1010}
                    height={643}
                    loading="lazy"
                  />
                  <img
                    className="asset-shadow w-full max-w-[522px] cursor-default select-none dark:hidden"
                    src="/static/home/hello-world.png"
                    alt="Expo workflow"
                    width={1010}
                    height={643}
                    loading="lazy"
                  />
                </picture>
              ),
              className: '-mt-80 max-lg-gutters:-mt-64',
            },
          ]}
          footer={{
            title: 'Use any library, SDK, or write your own native code',
            description:
              'You’ll never be limited by Expo. Write native code. Access every device API. Write anything, then run it everywhere.',
            content: (
              <FramerHorizontalScroll
                className={mergeClasses(
                  'mx-[-7.5%] -mb-4 gap-16 pb-8 pt-4',
                  'max-xl-gutters:-mx-6 max-xl-gutters:overflow-x-auto max-xl-gutters:px-6',
                  'max-md-gutters:-ml-16 max-md-gutters:pl-16'
                )}
                trackClassName={mergeClasses(
                  '-bottom-4',
                  'max-xl-gutters:inset-x-[33vw] max-xl-gutters:opacity-100',
                  'max-md-gutters:inset-x-16'
                )}>
                <DevelopAPITile
                  name="Maps"
                  href="https://docs.expo.dev/versions/latest/sdk/map-view/"
                  imageUrl="/static/home/api-maps.webp"
                  Icon={Map01Icon}
                />
                <DevelopAPITile
                  name="Camera"
                  href="https://docs.expo.dev/versions/latest/sdk/camera/"
                  imageUrl="/static/home/api-camera.webp"
                  Icon={Camera02Icon}
                />
                <DevelopAPITile
                  name="Image"
                  href="https://docs.expo.dev/versions/latest/sdk/image/"
                  imageUrl="/static/home/api-image.webp"
                  Icon={Image01Icon}
                />
                <DevelopAPITile
                  name="Navigation"
                  href="https://docs.expo.dev/routing/introduction/"
                  imageUrl="/static/home/api-navigation.webp"
                  Icon={Compass03Icon}
                />
              </FramerHorizontalScroll>
            ),
          }}
        />
      </Content>
    </section>
  );
}
