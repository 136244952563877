import { XLogoIcon } from '@expo/styleguide-icons/custom/XLogoIcon';

import { BoxContentContainer } from '~/ui/components/Box/BoxContentContainer';
import { BoxWrapper } from '~/ui/components/Box/BoxWrapper';
import { CALLOUT, P } from '~/ui/components/text';

import { TweetContent } from './TweetContent';

type Props = {
  tweet: {
    name: string;
    pictureUrl: string;
    username: string;
    content: string;
  };
};

export function XPostBox({ tweet }: Props) {
  return (
    <BoxWrapper key={tweet.username} className="w-[370px]">
      <BoxContentContainer>
        <div className="flex gap-2 pb-4">
          <img
            src={tweet.pictureUrl}
            height={44}
            width={44}
            loading="lazy"
            alt={`${tweet.name}'s avatar`}
            className="rounded-full"
          />
          <div>
            <P weight="semiBold">{tweet.name}</P>
            <CALLOUT theme="secondary" className="!leading-tight">
              @{tweet.username}
            </CALLOUT>
          </div>
          <XLogoIcon className="ml-auto min-w-[24px] text-palette-black dark:text-palette-white" />
        </div>
        <TweetContent content={tweet.content} />
      </BoxContentContainer>
    </BoxWrapper>
  );
}
