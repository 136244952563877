import { Button, mergeClasses } from '@expo/styleguide';
import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';

import { XPostBox } from '~/scenes/HomeScene/components/XPostBox';
import tweets from '~/scenes/HomeScene/data/tweets.json';
import { H2 } from '~/ui/components/text';
import Content from '~/ui/patterns/layouts/Content';

const tweetBoxes = tweets.map((tweet) => <XPostBox key={tweet.username} tweet={tweet} />);

export function SignUpSection() {
  return (
    <section className="relative overflow-hidden bg-palette-blue3 pb-28">
      <Content className="relative z-20 flex flex-col items-center gap-8">
        <div className="backface-hidden flex transform-gpu animate-slideLeft items-center gap-8 hocus:pause-animation">
          {tweetBoxes}
          {tweetBoxes}
        </div>
        <H2 size="xl" tag="h2" weight="black" className="mt-16 !text-center text-palette-blue12">
          Upgrade your team’s workflow
          <br />
          and iterate with stability.
        </H2>
        <Button
          size="2xl"
          rightSlot={<ArrowRightIcon />}
          className="mb-4 rounded-lg px-8"
          href="/signup">
          Sign Up
        </Button>
      </Content>
      <img
        width="140vw"
        height="120%"
        src="/static/home/signup-stripes.svg"
        className={mergeClasses(
          'absolute left-[-20vw] top-0 z-10 min-h-[120%] min-w-[140vw] opacity-60',
          'dark:opacity-[2.5%]',
          'max-xl-gutters:min-w-[160vw]',
          'max-lg-gutters:min-w-[180vw]',
          'max-md-gutters:min-w-[1600px]'
        )}
        alt="pattern"
      />
      <div className="absolute top-0 z-10 h-[158px] w-full bg-default" />
    </section>
  );
}
