import { mergeClasses } from '@expo/styleguide';
import { motion, Variants } from 'framer-motion';
import { ComponentType, HTMLAttributes } from 'react';

import { BoxWrapper } from '~/ui/components/Box/BoxWrapper';
import { H2, P } from '~/ui/components/text';

type Props = {
  Icon: ComponentType<HTMLAttributes<SVGSVGElement>>;
  title: string;
  description: string;
  iconClassName: string;
  position: number;
};

function getStatTileVariants(position: number): Variants {
  return {
    offscreen: {
      y: 80,
      scale: 0.66,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      scale: 1,
      opacity: 1,
      transition: {
        type: 'spring',
        bounce: 0,
        duration: 0.4 + 0.3 * position,
      },
    },
  };
}

export function StatsTile({ Icon, title, description, iconClassName, position }: Props) {
  return (
    <motion.div className="flex flex-col items-center" variants={getStatTileVariants(position)}>
      <BoxWrapper className="mb-4 p-2">
        <Icon className={mergeClasses('icon-xl', iconClassName)} />
      </BoxWrapper>
      <H2 tag="h3" className="!tracking-[0.01em]">
        {title}
      </H2>
      <P theme="secondary" className="text-center">
        {description}
      </P>
    </motion.div>
  );
}
