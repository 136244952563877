import { DEMI, H3 } from '~/ui/components/text';
import Content from '~/ui/patterns/layouts/Content';

export function StatementSection() {
  return (
    <section className="dark-theme bg-subtle py-28">
      <Content className="!max-w-[600px]">
        <H3 tag="p" theme="secondary" className="mb-8 !font-normal">
          <DEMI>
            We are the home for developers building native apps across Android and iOS with
            JavaScript (neat, right?).
          </DEMI>{' '}
          We’re open source. Offer a free plan. And are pretty-much the go-to if you’re building a
          mobile app.
        </H3>
        <H3 tag="p" theme="secondary" className="mb-8 !font-normal">
          <DEMI>
            We’re a full ecosystem of tools that help you write, build, update, submit, and monitor
            mobile apps.
          </DEMI>{' '}
          App store submission a pain? We’ve got you. Want developer tools in your app? Done. Our
          application services are built to help you ship faster. And if you get stuck, join our
          community of developers or say hello and meet our team.
        </H3>
        <H3 tag="p" theme="secondary">
          <DEMI>
            Behind every movie is a production team. Behind every great chef, someone running the
            restaurant.
          </DEMI>{' '}
          <span className="bg-statement-gradient bg-clip-text font-bold text-transparent">
            We’ll take care of the slow stuff so you and your team can focus on building the world’s
            best apps.
          </span>
        </H3>
      </Content>
    </section>
  );
}
