import { mergeClasses } from '@expo/styleguide';
import { BuildNumberIcon } from '@expo/styleguide-icons/custom/BuildNumberIcon';
import { DiscordIcon } from '@expo/styleguide-icons/custom/DiscordIcon';
import { Announcement03SolidIcon } from '@expo/styleguide-icons/solid/Announcement03SolidIcon';

import { LinkTile } from '~/scenes/HomeScene/components/LinkTile';
import { LinksDocsIllustration } from '~/scenes/HomeScene/illustrations/LinksDocsIllustration';
import { H2 } from '~/ui/components/text';
import Content from '~/ui/patterns/layouts/Content';

export function LinksSection() {
  return (
    <section className="border-t border-default bg-subtle py-24">
      <Content className="!max-w-screen-lg-gutters">
        <H2 className="mb-8 max-md-gutters:text-center">Want to learn more?</H2>
        <div
          className={mergeClasses(
            'grid grid-cols-4 gap-10',
            'max-lg-gutters:grid-cols-auto-min-2 max-lg-gutters:justify-center',
            'max-sm-gutters:grid-cols-auto-min-1'
          )}>
          <LinkTile
            href="https://docs.expo.dev"
            openInNewTab
            title="Read the docs"
            Icon={LinksDocsIllustration}
          />
          <LinkTile
            href="https://chat.expo.dev"
            openInNewTab
            title="Join the community"
            Icon={DiscordIcon}
          />
          <LinkTile href="/tools" title="Use our tools" Icon={BuildNumberIcon} />
          <LinkTile href="/blog" title="Read our blog" Icon={Announcement03SolidIcon} />
        </div>
      </Content>
    </section>
  );
}
