import { LinkBaseProps } from '@expo/styleguide';
import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';
import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';
import { ComponentType, HTMLAttributes } from 'react';

import { BoxWrapper } from '~/ui/components/Box/BoxWrapper';
import { LinkBase } from '~/ui/components/LinkBase';
import { PerspectiveHover } from '~/ui/patterns/PerspectiveHover';

type Props = LinkBaseProps & {
  title: string;
  Icon: ComponentType<HTMLAttributes<SVGSVGElement>>;
};

export function LinkTile({ href, openInNewTab, title, Icon }: Props) {
  const ArrowIcon = openInNewTab ? ArrowUpRightIcon : ArrowRightIcon;
  return (
    <PerspectiveHover perspective={650} speed={1200} scale={1.025}>
      <LinkBase skipNextLink={href?.includes('/blog')} href={href} openInNewTab={openInNewTab}>
        <BoxWrapper className="flex w-56 flex-col items-center overflow-hidden">
          <Icon className="icon-2xl m-8 text-default" />
          <div className="flex w-full items-center border-t border-t-default bg-subtle px-4 py-2 text-secondary">
            {title}
            <ArrowIcon className="ml-auto" />
          </div>
        </BoxWrapper>
      </LinkBase>
    </PerspectiveHover>
  );
}
